import React from "react";
import leftImage from "../assets/bg2.png"; // Replace with actual image path
import rightImage from "../assets/bg7.jpg"; // Replace with actual image path

const TechEnabledSection = () => {
  return (
    <section className="bg-customBlue py-20 font-poppins">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <div className="container mx-auto text-center px-6 md:px-20">
        {/* Title */}
        <h2 className="font-archivo-black text-left md:text-center text-2xl md:text-4xl font-bold text-white mb-6">
          QUICKEST TAT
        </h2>

        {/* Description */}
        <p className="font-poppins text-white text-left md:text-center text-lg md:text-xl leading-relaxed mb-12 max-w-3xl mx-auto">
          Our specialized recruiters have the ability to filter and screen a vast database of top talent, providing you with skilled and qualified candidates within 24 working hours. With a shortened TAT, our core service is focused on filling mandates swiftly.
        </p>

        {/* Images */}
        <div className="flex flex-col md:flex-row justify-center items-center gap-8">
          {/* Left Image */}
          <div className="flex justify-center w-full md:w-1/2">
            <img
              src={leftImage} // Use the actual image path
              alt="Laptop showing job requests"
              className="w-80 md:w-full h-auto object-cover"
            />
          </div>

          {/* Right Image */}
          <div className="flex justify-center w-full md:w-1/2">
            <img
              src={rightImage} // Use the actual image path
              alt="Laptop showing candidate profile"
              className="w-80 md:w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechEnabledSection;
