import React from 'react';

const OfficesSection = () => {
  return (
    <section className="bg-gray-100 text-customBlue py-12 px-8 md:px-12 lg:px-24">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
        .font-archivo-black {
          font-family: 'Archivo Black', sans-serif;
        }
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }

        /* Hover effect styles */
        .contact-box {
          transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
        }

        .contact-box:hover {
          transform: scale(1.05); /* Slightly enlarge the box on hover */
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow effect on hover */
        }
      `}</style>
      
      <div className="text-center mb-8">
        <h2 className="font-archivo-black md:text-3xl text-xl font-bold text-customBlue mb-4">
          CONNECT WITH US
        </h2>
        <p className=" text-customBlue font-poppins text-xl">
          We would love to hear from you. Get in touch with us for any queries or assistance.
        </p>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="lg:w-1/2 text-center bg-customBlue shadow-lg p-8 rounded-lg contact-box">
          {/* Clickable email link */}
          <a href="mailto:inquiry@onboardhrservices.com" className="font-poppins text-lg text-white mb-4 block">
            inquiry@onboardhrservices.com
          </a>
          
          <p className="font-poppins text-lg text-white">
           HQ: Mumbai, India
          </p>
        </div>
      </div>
    </section>
  );
};

export default OfficesSection;
