import React, { useState, useRef, useEffect } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hb9 from '../components/Hb9';
import img3 from '../assets/corporate.jpg';

// Import the missing images
import img from '../assets/group-people-working-out-business-plan-office.jpg'; 
import img1 from '../assets/bg10.png'; 
import icon1 from '../assets/icon1.png';
import icon2 from '../assets/icon2.png';
import icon3 from '../assets/icon3.png';

import toggleImage1 from '../assets/friendly-team-members-chatting-laughing-together-office-break.jpg'; // Replace with actual path
import toggleImage2 from '../assets/hr-representatives-positively-greeting-female-job-candidate.jpg'; // Replace with actual path

const AboutUsPage = () => {
  // State to handle the current image
  const [currentImage, setCurrentImage] = useState(toggleImage1);

  // Create a reference for the Hb9 section
  const hb9Ref = useRef(null);

  // Function to scroll to the Hb9 section
  const scrollToHb9 = () => {
    hb9Ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Function to toggle images
    const toggleImages = () => {
      setCurrentImage((prevImage) => {
        if (prevImage === toggleImage1) {
          return toggleImage2;
        } else {
          return toggleImage1;
        }
      });
    };

    // Interval to change images every 5 seconds
    const intervalId = setInterval(toggleImages, 5000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="font-poppins"> {/* Apply Poppins font to the whole page */}
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
        .font-archivo-black {
          font-family: 'Archivo Black', sans-serif;
        }
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }
      `}</style>

      <Header />

      {/* Section 1 */}
      <section className="flex flex-col md:flex-row items-center justify-between py-16 px-8 md:px-16 lg:px-32">
        <div className="md:w-1/2 space-y-6">
          <h1 className="font-archivo-black text-2xl md:text-4xl font-bold text-customBlue leading-tight">
            ONBOARD HRSERVICES
          </h1>
          <p className="font-poppins text-lg md:text-xl text-customBlue">
          Established in 2015, Onboard HRServices has been a trusted recruitment  partner, bringing over a decade of expertise in matching top talent with global business conglomerates. We believe the right people drive success and are dedicated to connecting exceptional professionals with organizations to shape a thriving future.
          </p>
          <button 
            className="mt-6 bg-customBlue text-white py-3 px-6 font-bold hover:bg-orange-500 transition-colors"
            onClick={scrollToHb9} // Scroll to the Hb9 section when clicked
          >
            LET'S CONNECT!
          </button>
        </div>

        {/* Image Section with Toggle */}
        <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center relative">
          <div className="absolute right-[-10%] top-[50%] w-[120%] h-[120%] bg-customBlue md:right-[-30%] md:top-[-43%] md:w-[80%] md:h-[187%]"></div>
          <img
            src={currentImage}
            alt="Toggle Image"
            className="relative z-10 max-w-full h-auto md:w-2/3"
          />
        </div>
      </section>

      {/* Section 2 */}
      <section className="bg-customBlue py-16 px-8 md:px-16 lg:px-32 text-white">
        <div className="max-w-4xl mx-auto">
          <h1 className="font-archivo-black text-2xl md:text-4xl lg:text-3xl leading-tight">
          EXPERT STAFFING FOR INDUSTRY LEADERS
          </h1>
          <p className="font-poppins mt-4 text-lg md:text-xl lg:text-xl leading-relaxed">
            Specializing in end-to-end staffing solutions for Fortune 500 companies and global brands, we cater to various needs, including permanent and contract staffing, particularly for middle and senior management roles.
          </p>
        </div>
      </section>

      {/* Section 3 */}
      <section className="flex flex-col md:flex-row items-center justify-between py-16 px-8 md:px-16 lg:px-32 bg-white">
        <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
          <img
            src={img}
            alt="Recruitment image"
            className="max-w-full h-auto md:w-[85%]"
          />
        </div>

        <div className="mt-8 md:mt-0 md:w-1/2 space-y-4 text-white">
          <h1 className="text-customBlue font-archivo-black text-2xl md:text-3xl font-bold">
            JUMP ON IN.
            <br />
            THE WATER’S WARM.
          </h1>
          <p className="text-customBlue font-poppins text-lg md:text-xl">
            We’ve developed a culture that attracts and empowers the best RECRUITEMENT talent. Through respect, professionalism, and personality, we’re a talent destination.
          </p>
         
        </div>
      </section>

      {/* Section 4 */}
      <section className="flex flex-col md:flex-row items-center justify-between py-16 px-8 md:px-16 lg:px-32 bg-white">
        <div className="md:w-1/2 space-y-6">
          <h1 className="font-archivo-black text-2xl md:text-3xl font-bold text-customBlue">
            MISSION AND VALUES
          </h1>
          <p className="font-poppins text-lg md:text-xl text-customBlue">
            At Onboard HRServices, we thrive to shape careers. Our team acts as brand ambassadors, passionately committed to ensuring both clients and professionals thrive in their partnerships​.
          </p>
          
        </div>

        <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
          <img
            src={img3}
            alt="Culture add vs fit"
            className="max-w-full h-auto md:w-2/3"
          />
        </div>
      </section>

      {/* Section 5 */}
      <section className="py-16 bg-white">
        <div className="bg-customBlue py-8 w-full">
          <h2 className="font-archivo-black text-2xl md:text-3xl font-bold text-white text-center">
            PARTNER WITH US FOR TRUSTED TALENT SOLUTIONS
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12 px-8 md:px-16 lg:px-32">
          <div className="flex flex-col items-center text-center">
            <img src={icon1} alt="People First" className="h-24 w-24 mb-4" />
            <h3 className="font-archivo-black text-xl font-bold text-customBlue mb-4">BUSINESS ETHICS</h3>
            <p className="font-poppins text-customBlue text-xl">
              We uphold the highest ethical standards, ensuring trust, transparency, and integrity in every partnership we build.
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img src={icon2} alt="Accelerate Your Career" className="h-24 w-24 mb-4" />
            <h3 className="font-archivo-black text-xl font-bold text-customBlue mb-4">INTEGRITY</h3>
            <p className="font-poppins text-customBlue text-xl">
              We commit to integrity in every action, fostering transparent, honest relationships that ensure mutual respect and trust.
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img src={icon3} alt="Leaders That Empower" className="h-24 w-24 mb-4" />
            <h3 className="font-archivo-black text-xl font-bold text-customBlue mb-4">ONE STOP SHOP SERVICES</h3>
            <p className="font-poppins text-customBlue text-xl">
              We provide seamless, end-to-end recruitment solutions, making us your one stop shop for all talent needs.
            </p>
          </div>
        </div>
      </section>

      {/* Section with background */}
      <section
        className="relative h-[500px] flex justify-center items-center"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <h1 className="font-archivo-black relative text-white text-2xl md:text-4xl font-bold text-center">
          YOUR RECRUITMENT CHANNEL
        </h1>
      </section>

      {/* Attach the reference to the Hb9 component */}
      <div ref={hb9Ref}>
        <Hb9 />
      </div>
      
      <Footer />
    </div>
  );
};

export default AboutUsPage;
