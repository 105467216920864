import React from 'react';

const Notdiff = () => {
  return (
    <div className="relative mt-8 md:mt-24 font-poppins">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      {/* Adjusted top for mobile (top-[80%]) and original for desktop (md:top-[50%]) */}
      <div className="absolute inset-x-0 top-[80%] md:top-[50%] transform -translate-y-1/2">
        <p className="text-center">
          <span className="bg-gray-100 font-archivo-black text-customBlue text-lg md:text-3xl lg:text-3xl leading-tight md:leading-normal lg:leading-normal px-4 md:px-6 lg:px-8 py-2 md:py-3 lg:py-4">
          OUR RESULTS, YOUR SUCCESS
          </span>
        </p>
      </div>
    </div>
  );
};

export default Notdiff;
