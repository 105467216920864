import React, { useRef } from 'react';
import recruitingImage from "../assets/corporate4.jpg";
import Header from '../components/Header';
import DifferentlySection from '../components/DifferentlySection';
import CardSection from '../components/CardSecion';
import WhatWeDeliver from '../components/Whatwedeliver';
import Hb9 from '../components/Hb9';
import TechEnabledSection from '../components/TechSection';
import ClientsSection from '../components/ClientSection';
import Footer from '../components/Footer';

function Service() {
  const hb9Ref = useRef(null); // Create a reference for the Hb9 component

  const scrollToHb9 = () => {
    hb9Ref.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the Hb9 component smoothly
  };

  return (
    <>
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <link href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap" rel="stylesheet" />
      <style>{`
        body {
          font-family: 'Poppins', sans-serif;
        }
        .font-archivo-black {
          font-family: 'Archivo Black', sans-serif;
        }
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }
      `}</style>

      <div className="font-poppins"> {/* Use 'Poppins' font globally as a default */}
        <Header />
        
        {/* Section 1 */}
        <section className="bg-customBlue text-white p-6 lg:p-16 flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 lg:pr-12">
            <h2 className="font-archivo-black text-xl lg:text-4xl font-bold lg:pl-12">
              WORKFORCE: <span className="text-white">REDEFINED</span>
            </h2>
            <p className="font-poppins mt-4 lg:mt-6 text-lg lg:text-2xl leading-relaxed lg:pl-12">
              Harness strategic, tech-powered talent acquisition with tailored solutions designed to propel your business forward.
            </p>
            <button 
              className="mt-6 lg:mt-8 bg-customY text-white py-3 px-6 lg:px-8 hover:bg-opacity-90 font-bold transition-colors lg:ml-12"
              onClick={scrollToHb9} // Scroll to the Hb9 section when clicked
            >
              LET'S CONNECT!
            </button>
          </div>
          <div className="mt-6 lg:mt-0 lg:w-1/2 flex justify-center">
            <div className="relative w-64 h-64 lg:w-80 lg:h-80">
              <img
                src={recruitingImage}
                alt="Recruiting Redefined"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </section>

        <DifferentlySection />
        
        {/* Section 2 */}
        <section className="bg-customY text-white py-20 px-6 md:px-40 flex flex-col md:flex-row items-start md:items-center justify-between">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <h2 className="font-archivo-black text-2xl md:text-4xl font-bold text-left">
              EXPERTS IN <br /> SPECIALIZING
            </h2>
          </div>
          <div className="w-full md:w-1/2">
            <p className="font-poppins text-lg md:text-xl leading-relaxed text-left">
              We’ll place the right talent because our recruiters speak your
              <span className="font-bold"> “language.”</span> We know how to ask the right questions.
            </p>
          </div>
        </section>

        <CardSection />
        <WhatWeDeliver />
        <TechEnabledSection />
      

        {/* Hb9 Section */}
        <div ref={hb9Ref}> {/* Attach the reference to the Hb9 component */}
          <Hb9 />
        </div>
        
        <Footer />
      </div>
    </>
  );
}

export default Service;
