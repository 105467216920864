import React, { useState } from 'react';

const CVForm = ({ closeForm }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    cv: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here, such as sending data to a server
    console.log(formData);
    closeForm(); // Close the form on submit
  };

  return (
    <div className="fixed top-0 right-0 w-80 h-full bg-white shadow-lg transform transition-transform duration-300 z-50 text-customBlue">
      <div className="p-4 flex justify-between items-center border-b border-customBlue">
        <h2 className="text-lg font-semibold">Submit Your CV</h2>
        <button onClick={closeForm} className="text-customBlue text-2xl font-semibold">
          &times;
        </button>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* First Name */}
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="mt-1 p-2 block w-full border border-gray-300 rounded focus:outline-none focus:ring focus:ring-customY"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>

          {/* Last Name */}
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="mt-1 p-2 block w-full border border-gray-300 rounded focus:outline-none focus:ring focus:ring-customY"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>

          {/* Phone Number */}
          <div>
            <label htmlFor="phone" className="block text-sm font-medium">
              Phone Number
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="mt-1 p-2 block w-full border border-gray-300 rounded focus:outline-none focus:ring focus:ring-customY"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          {/* Upload CV */}
          <div>
            <label htmlFor="cv" className="block text-sm font-medium">
              Upload CV
            </label>
            <input
              type="file"
              id="cv"
              name="cv"
              accept=".pdf,.doc,.docx"
              className="mt-1 p-2 block w-full border border-gray-300 rounded focus:outline-none focus:ring focus:ring-customY"
              onChange={handleChange}
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-customY text-white w-full py-2 rounded mt-2 focus:outline-none hover:bg-yellow-600"
          >
            Submit Your CV
          </button>
        </form>
      </div>
    </div>
  );
};

export default CVForm;
