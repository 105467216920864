import React from "react";
import backgroundImage from "../assets/crew3.jpg"; // Add your background image

const AviationInspirationSection = () => {
  return (
    <section
      className="relative w-full h-[65vh] md:h-[75vh] flex items-center justify-center text-center bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Text Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4">
          {/* Quote Box 1 */}
          <div className="w-full sm:w-64 lg:w-96 h-24 md:h-36 bg-white bg-opacity-80 p-4 md:p-6 rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105 flex items-center justify-center">
            <h3 className="text-sm md:text-lg font-archivo-black text-black mb-2 text-center">
              Jetting off to new horizons
            </h3>
          </div>

          {/* Quote Box 2 */}
          <div className="w-full sm:w-64 lg:w-96 h-24 md:h-36 bg-white bg-opacity-80 p-4 md:p-6 rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105 flex items-center justify-center">
            <h3 className="text-sm md:text-lg font-archivo-black text-black mb-2 text-center">
              Flap your wings and fly high
            </h3>
          </div>

          {/* Quote Box 3 */}
          <div className="w-full sm:w-64 lg:w-96 h-24 md:h-36 bg-white bg-opacity-80 p-4 md:p-6 rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105 flex items-center justify-center">
            <h3 className="text-sm md:text-lg font-archivo-black text-black mb-2 text-center">
            Exciting career opportunity for all Aviation Enthusiasts to join the league
            </h3>
          </div>
        </div>

        {/* Apply Now Call-to-Action */}
        <div className="mt-6 md:mt-8 flex justify-center">
          <a
            href="mailto:shraddha@onboardhrservices.com?subject=Application for Aviation Opportunity"
            className="bg-customBlue text-white py-2 md:py-3 px-4 md:px-6 font-semibold text-sm md:text-lg shadow-md transition duration-300"
          >
            Apply Now !!
          </a>
        </div>
      </div>
    </section>
  );
};

export default AviationInspirationSection;
