import React, { useState } from 'react';
// Import logos for each sector
import aviationLogo from '../assets/plane.png';
import travelLogo from '../assets/plane (2).png';
import bankingLogo from '../assets/bank.png';
import retailLogo from '../assets/shopping-cart-check.png';
import logisticsLogo from '../assets/supply-chain.png';
import mediaLogo from '../assets/media.png';
import consultingLogo from '../assets/corporate.png';
import insuranceLogo from '../assets/user-shield.png';
import facilityLogo from '../assets/document-gear.png';

const SectorsList = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Updated sectors data with full content text
  const sectors = [
    { 
      name: "Aviation & Hospitality",
      content: "In the dynamic world of aviation and hospitality, where excellence is the standard, Onboard HRServices excels in identifying exceptional talent. With a deep understanding of the industry's intricacies and the specialized expertise required, we seamlessly fill leadership roles, enabling our clients to soar to new heights. Our global perspective combined with a localized approach ensures that our clients acquire the finest professionals, expanding their boundaries and elevating their service standards.",
      logo: aviationLogo 
    },
    { 
      name: "Travel & Tourism",
      content: "In the vibrant world of travel and tourism, Onboard HRServices is a trusted partner in talent acquisition. We understand the industry's unique requirements and seamlessly identify exceptional leaders who drive success. By connecting our clients with professionals who possess a passion for hospitality, customer-centricity, and business acumen, we empower them to elevate guest experiences, drive revenue growth, and stay ahead in this rapidly evolving sector.",
      logo: travelLogo
    },
    { 
      name: "Banking & Non-Banking Financial Services",
      content: "In the fast-paced realm of banking and non-banking financial services, Onboard HRServices stands out as a trusted partner in talent acquisition. With a comprehensive understanding of the industry's demands, we navigate the complexities to identify top-tier professionals for leadership positions. Our expertise, coupled with a keen eye for emerging trends, enables our clients to secure exceptional leaders who drive growth, innovation, and success in this competitive sector.",
      logo: bankingLogo
    },
    { 
      name: "Retail & E-Commerce",
      content: "The retail and e-commerce sector thrives on agility and adaptability, and Onboard HRServices understands these crucial requirements. With our expertise in talent acquisition, we seamlessly identify exceptional leaders who drive success in this competitive industry. By understanding market dynamics, customer trends, and technological advancements, we help our clients secure visionary professionals who accelerate growth, enhance customer experiences, and navigate the digital transformation journey.",
      logo: retailLogo
    },
    { 
      name: "Logistics",
      content: "In the fast-paced world of logistics, Onboard HRServices understands the importance of efficient and reliable talent. We seamlessly fill leadership positions in the logistics sector, connecting our clients with professionals who possess a keen understanding of supply chain management, technological advancements, and operational excellence. By ensuring the right fit, we help our clients optimize processes, enhance customer experiences, and drive growth in this crucial industry.",
      logo: logisticsLogo
    },
    { 
      name: "Media & Entertainment",
      content: "The vibrant world of media and entertainment demands a keen eye for talent, and Onboard HRServices is dedicated to delivering the best. With our in-depth knowledge of this dynamic industry, we seamlessly fill leadership roles, connecting our clients with visionary professionals who bring creativity, innovation, and industry insights. Our commitment to staying ahead of the curve and understanding evolving trends ensures that our clients thrive in this ever-evolving landscape.",
      logo: mediaLogo
    },
  
    {
      name: "Real Estate",
      content: "Onboard HRServices is a trusted recruitment partner for top real estate firms. We understand the dynamic nature of the real estate industry, where expertise in property development, market analysis, and project management is essential. Our specialized recruitment process ensures we connect firms with experienced professionals who excel in real estate transactions, investment strategies, and operational efficiency. By providing access to a pool of industry experts, we enable our clients to thrive in a competitive market, delivering exceptional value and growth in every project.",
      logo: consultingLogo
    },
    
    { 
      name: "Insurance & Broking",
      content: "The insurance and broking sector demands precision, expertise, and unwavering commitment to customer satisfaction. Onboard HRServices excels in identifying professionals who possess these qualities. With our thorough understanding of the industry's intricacies, we seamlessly fill leadership positions, connecting our clients with talented individuals who drive growth, innovation, and exceptional service standards. Our focus on industry trends and customer-centric approaches ensures that our clients thrive in this competitive landscape.",
      logo: insuranceLogo
    },
    { 
      name: "Facility Management",
      content: "Facility management requires expertise in coordinating complex operations seamlessly. Onboard HRServices excels in identifying professionals who possess the necessary skills and knowledge in this field. By understanding the intricacies of facility management, we seamlessly fill leadership positions, connecting our clients with experienced individuals who streamline operations, ensure efficiency, and deliver exceptional service standards. Our commitment to quality and attention to detail set us apart in this vital sector.",
      logo: facilityLogo
    }
  ];

  const toggleActive = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle active index
  };

  return (
    <section className="bg-customBlue text-white py-12 font-poppins">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
          .logo-img {
            width: 40px; 
            height: 40px;
            filter: brightness(0) invert(1); /* Converts logos to white */
            margin-right: 20px; 
          }
          .bold-logo {
            width: 50px; /* Make the logo slightly larger for bold effect */
            height: 50px;
          }
        `}
      </style>
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 gap-6">
          {sectors.map((sector, index) => (
            <div 
              key={index} 
              className={`py-4 px-6 border-b border-white cursor-pointer transition-colors duration-300 ${
                activeIndex === index ? 'bg-customBlue text-white' : 'bg-customBlue text-white'
              }`} 
              onClick={() => toggleActive(index)}
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  {/* Apply bold-logo class specifically to Travel & Tourism */}
                  <img 
                    src={sector.logo} 
                    alt={`${sector.name} logo`} 
                    className={`logo-img ${sector.name === 'Travel & Tourism' ? 'bold-logo' : ''}`} 
                  />
                  <h3 className="font-archivo-black text-xl">{sector.name}</h3>
                </div>
                <span className={`text-xl font-bold ${
                  activeIndex === index ? 'text-customBlue' : 'text-white'
                }`}>
                  {activeIndex === index ? '-' : '+'} {/* Toggle between + and - */}
                </span>
              </div>
              {activeIndex === index && (
                <div className="mt-4 font-poppins text-lg">
                  <p>{sector.content}</p> {/* Dynamic content */}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectorsList;
