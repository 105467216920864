import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

// Import your logos
import logo1 from "../assets/client.png"; // Replace with actual logo paths
import logo2 from "../assets/database.png"; // Replace with actual logo paths
import logo3 from "../assets/icons8-real-estate-100.png"; // Replace with actual logo paths
import logo4 from "../assets/icons8-people-100.png"; // Replace with actual logo paths

const StatisticsSection = () => {
  const [count, setCount] = useState(0);
  const [reset, setReset] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setReset(true); // Trigger reset
      setCount((prev) => prev + 1); // Increment count for key change
    }, 5000); // Repeat every 5 seconds

    return () => {
      clearInterval(interval); // Cleanup the interval on component unmount
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`py-8 md:py-12 font-poppins transition-colors duration-500 bg-customBlue`}
    >
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
          .stat-logo {
            max-width: 60px; /* Adjust the logo size for mobile */
            margin-bottom: 16px;
          }
          @media (min-width: 768px) {
            .stat-logo {
              max-width: 80px; /* Larger logo size for bigger screens */
            }
          }
        `}
      </style>

      <div className="text-center text-white mb-4 md:mb-10 px-4 sm:px-0">
        <h2 className="font-archivo-black text-md md:text-xl lg:text-2xl">
          We've hit milestones that make a difference—numbers you can count on.
        </h2>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 px-4 sm:px-10 text-white">
        {/* Clients */}
        <div className="text-center">
          <img src={logo1} alt="Clients" className="stat-logo mx-auto" />
          <div className="text-xl md:text-3xl font-bold">
            <CountUp key={count} start={0} end={50} duration={2} reset={reset} />+
          </div>
          <p className="font-poppins mt-2 text-xs md:text-lg lg:text-xl text-white font-semibold">
            CLIENTS
          </p>
        </div>

        {/* Candidate Database */}
        <div className="text-center">
          <img src={logo2} alt="Candidate Database" className="stat-logo mx-auto" />
          <div className="text-xl md:text-3xl font-bold">
            <CountUp key={count + 1} start={0} end={500000} duration={3} reset={reset} />+
          </div>
          <p className="font-poppins mt-2 text-xs md:text-lg lg:text-xl text-white font-semibold">
            CANDIDATE DATABASE
          </p>
        </div>

        {/* Industry Practices */}
        <div className="text-center">
          <img src={logo3} alt="Industry Practices" className="stat-logo mx-auto" />
          <div className="text-xl md:text-3xl font-bold">
            <CountUp key={count + 2} start={0} end={12} duration={2} reset={reset} />+
          </div>
          <p className="font-poppins mt-2 text-xs md:text-lg lg:text-xl text-white font-semibold">
            INDUSTRY PRACTICES
          </p>
        </div>

        {/* Placements */}
        <div className="text-center">
          <img src={logo4} alt="Placements" className="stat-logo mx-auto" />
          <div className="text-xl md:text-3xl font-bold">
            <CountUp key={count + 3} start={0} end={10000} duration={3} reset={reset} />+
          </div>
          <p className="font-poppins mt-2 text-xs md:text-lg lg:text-xl text-white font-semibold">
            PLACEMENT ACROSS INDUSTRIES
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSection;
