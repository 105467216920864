import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';

const ContactUsSection = () => {
  return (
    <section className="bg-customBlue text-white py-12 flex items-center justify-center font-poppins">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
        .font-archivo-black {
          font-family: 'Archivo Black', sans-serif;
        }
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }
      `}</style>

      <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row items-center text-left px-6 md:px-8 lg:px-24">
        {/* Icon Section - Will show on the left in mobile view */}
        <div className="text-6xl md:text-7xl lg:text-8xl mb-6 md:mb-0 w-full md:w-1/3 lg:w-1/4 flex justify-start md:justify-end pr-8 md:pr-12 lg:pr-16">
          <FaPhoneAlt />
        </div>

        {/* Text Section */}
        <div className="w-full md:w-2/3 lg:w-3/4"> {/* Adjusted width for larger screens */}
          <h2 className="font-archivo-black text-2xl md:text-4xl font-semibold mb-5">CONTACT US</h2>
          <p className="font-poppins text-lg md:text-xl leading-relaxed">
            Have a question? Want to connect with one of our businesses? We’ve got you covered. Connect with us directly using the form or the information below.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
