import React, { useState } from 'react';
import careerChangeImage from '../assets/corporate2.jpg'; // Ensure the correct path
import CVForm from './Cvform'; // Import the CVForm component

const CareerChangeSection = () => {
  const [isCVFormOpen, setIsCVFormOpen] = useState(false); // State to manage form visibility

  const openCVForm = () => {
    setIsCVFormOpen(true); // Open the form
  };

  const closeCVForm = () => {
    setIsCVFormOpen(false); // Close the form
  };

  return (
    <section className="py-8 md:py-10 lg:py-12 bg-customBlue font-poppins relative"> {/* Adjusted vertical padding for different views */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 lg:px-10 xl:px-16"> {/* Reduced padding for desktop view */}
        
        {/* Text Section */}
        <div className="md:w-1/2">
          <h2 className="font-archivo-black text-xl md:text-3xl font-bold text-white mb-4">
            READY TO TAKE THE NEXT STEP?
          </h2>
          <p className="font-poppins text-lg md:text-xl text-white mb-6">
            Submit your resume and explore opportunities that align with your career goals.
          </p>
          <button 
            className="bg-white text-customBlue font-bold py-2 px-6 text-lg"
            onClick={openCVForm} // Open the CV form on click
          >
            Submit Your Resume
          </button>
        </div>

        {/* Image Section */}
        <div className="hidden md:block md:w-1/2 ml-auto pl-8 lg:pl-12 xl:pl-16"> {/* Adjusted padding for desktop view */}
          <img src={careerChangeImage} alt="Career Change" className="w-full h-auto shadow-md" />
        </div>
        
      </div>

      {/* CV Form Panel */}
      {isCVFormOpen && <CVForm closeForm={closeCVForm} />}
    </section>
  );
};

export default CareerChangeSection;
