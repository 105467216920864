import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUsers, faPeopleArrows, faStar, faMap, faHeart } from "@fortawesome/free-solid-svg-icons"; // Import new icon

const CardSection = () => {
  const cards = [
    {
      title: "Executive Search",
      description:
        "Our Executive Search service connects you with high-caliber leadership talent. By leveraging our extensive network and deep industry knowledge, we secure senior executives who align with your business strategy. We prioritize candidates with the expertise to drive your company’s long-term success, ensuring strategic leadership that propels growth.",
      icon: <FontAwesomeIcon icon={faSearch} />, // Use FontAwesome icon
    },
    {
      title: "Middle & Senior Hiring",
      description:
        "Our specialized service streamlines recruitment for middle and senior management roles. We access a vast pool of experienced candidates who bring valuable skills and cultural alignment to your organization. This service ensures seamless integration of talent that contributes to operational efficiency and drives your company's growth objectives.",
      icon: <FontAwesomeIcon icon={faUsers} />, // Use FontAwesome icon
    },
    {
      title: "Volume Hiring",
      description:
        "We excel in high-volume recruitment, providing scalable staffing solutions for large teams or departments. Our methodical approach allows us to meet demanding timelines while ensuring each candidate is a strong fit. We prioritize efficiency without compromising on quality, enabling your business to scale rapidly and effectively.",
      icon: <FontAwesomeIcon icon={faPeopleArrows} />, // Use FontAwesome icon
    },
    {
      title: "Leadership/Niche Search",
      description:
        "For specialized roles or niche markets, our Leadership/Niche Search service offers tailored recruitment strategies. We identify and connect you with professionals who possess the unique skills and expertise needed for your specific business needs. Our deep understanding of industry dynamics allows us to find talent that drives innovation.",
      icon: <FontAwesomeIcon icon={faStar} />, // Use FontAwesome icon
    },
    {
      title: "Market/Industry Mapping",
      description:
        "Stay ahead of market trends with our Market and Industry Mapping service. We provide in-depth analysis, insights, and research on key players and emerging trends in your industry. This service equips you with the knowledge to make informed, strategic decisions, positioning your business for future opportunities and challenges.",
      icon: <FontAwesomeIcon icon={faMap} />, // Use FontAwesome icon
    },
    {
      title: "PRIDE & PWD Hiring",
      description:
        "We foster inclusion through dedicated hiring for LGBTQIA+ and PWD professionals, connecting you with a diverse talent pool that enriches your organization. Our tailored approach ensures the integration of individuals with unique perspectives and skills, creating a culture of belonging that drives innovation and business success.",
      icon: <FontAwesomeIcon icon={faHeart} />, // Use FontAwesome icon (heart icon to signify care and inclusion)
    },
  ];

  // Track the currently expanded card index. Initialize to -1, which means no card is expanded.
  const [expandedCardIndex, setExpandedCardIndex] = useState(-1);

  // Toggle expand the selected card. If it's already expanded, collapse it.
  const toggleExpand = (index) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  // Truncate the text based on whether the card is expanded or not.
  const truncateText = (text, isExpanded) => {
    return isExpanded ? text : `${text.substring(0, 100)}...`;
  };

  return (
    <section className="bg-white py-16 font-poppins">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-center">
        {cards.map((card, index) => {
          // Determine if the current card is expanded.
          const isExpanded = expandedCardIndex === index;
          return (
            <div
              key={index}
              className="bg-customBlue p-8 flex flex-col h-full max-w-lg mx-auto"
            >
              <div className="flex items-center mb-4">
                <div className="text-white text-3xl mr-4">
                  {card.icon} {/* Render the FontAwesome icon */}
                </div>
                <div>
                  <h3 className="font-archivo-black text-xl font-bold text-white uppercase">
                    {card.title}
                  </h3>
                </div>
              </div>
              <p className="font-poppins text-white mb-4 mt-4">
                {truncateText(card.description, isExpanded)}
              </p>
              <button
                onClick={() => toggleExpand(index)}
                className="text-customY font-bold mt-auto text-left"
              >
                {isExpanded ? "Show Less" : "Read More"}
              </button>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CardSection;
