import React from 'react';

const DropUsALineForm = () => {
  return (
    <section className="py-12 bg-gray-300 flex justify-center font-poppins">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <div className="w-full max-w-xl px-4">
        <h1 className="font-archivo-black md:text-3xl text-2xl font-bold text-customBlue text-center mb-6">
          CONNECT WITH US
        </h1>
        <p className="font-poppins md:text-md text-sm text-customBlue font-semibold text-center mb-8">
          We connect businesses with the talent they need to drive growth and individuals with opportunities that enhance their careers. Whether you're hiring or looking for your next role, reach out to us today!
        </p>
        <form className="space-y-4">
          <div className="flex flex-col md:flex-row md:space-x-4">
            <input
              type="text"
              placeholder="First name*"
              className="w-full md:w-1/2 px-4 py-2 bg-white text-gray-700 rounded-full focus:outline-none"
              required
            />
            <input
              type="text"
              placeholder="Last name*"
              className="w-full md:w-1/2 px-4 py-2 bg-white text-gray-700 rounded-full focus:outline-none mt-4 md:mt-0"
              required
            />
          </div>
          <input
            type="email"
            placeholder="Email*"
            className="w-full px-4 py-2 bg-white text-gray-700 rounded-full focus:outline-none"
            required
          />
          <input
            type="text"
            placeholder="Company name*"
            className="w-full px-4 py-2 bg-white text-gray-700 rounded-full focus:outline-none"
            required
          />
          <textarea
            placeholder="How can we help you hire?*"
            className="w-full px-4 py-2 bg-white text-gray-700 rounded-lg focus:outline-none h-28 resize-none"
            required
          />
          <textarea
            placeholder="How did you hear about us?*"
            className="w-full px-4 py-2 bg-white text-gray-700 rounded-lg focus:outline-none h-28 resize-none"
            required
          />
          
          {/* Align the checkbox and label just below the text area */}
          
          
          <button
            type="submit"
            className="py-3 px-6 bg-customBlue text-white font-extrabold rounded-md hover:bg-gray-700 transition-colors duration-300"
            style={{ textAlign: 'left', width: 'auto' }}
          >
            SUBMIT
          </button>
        </form>
      </div>
    </section>
  );
};

export default DropUsALineForm;
