import React from "react";
import { useNavigate } from "react-router-dom";
import leftImage from "../assets/girl.png"; // Replace with the correct path

const JoinRevolutionSection = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleLearnMoreClick = () => {
    // Navigate to the aviation page when the button is clicked
    navigate("/aviation");
  };

  return (
    <section className="py-12 bg-white font-poppins">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-0">
        
        {/* Left Image Section */}
        <div className="md:w-1/2 flex justify-center md:justify-start mb-6 md:mb-0 md:pl-10"> {/* Added md:pl-10 for left padding */}
          <img
            src={leftImage}
            alt="Team Collaboration"
            className="w-full md:w-3/4 h-auto md:ml-10" // Added md:ml-10 for additional right spacing in desktop view
          />
        </div>

        {/* Text Section */}
        <div className="md:w-1/2 md:pl-12 text-center md:text-left">
          {/* Responsive text alignment */}
          <h2 className="font-archivo-black md:text-4xl text-xl font-bold text-customBlue mb-4">
            JOIN THE REVOLUTION
          </h2>
          <p className="font-poppins text-md md:text-lg text-customBlue mb-6">
            Take your career to new heights with us. As an aspiring pilot, passionate cabin crew member, or dedicated ground staff professional, we offer unparalleled opportunities across the spectrum. SUBMIT YOUR RESUME TODAY and elevate your career with industry leaders. Soar with us—your journey starts now.
          </p>
          {/* Using navigate function to go to /aviation on button click */}
          <button
            className="bg-customBlue font-bold text-white py-2 px-6 text-lg"
            onClick={handleLearnMoreClick}
          >
            LEARN MORE
          </button>
        </div>
      </div>
    </section>
  );
};

export default JoinRevolutionSection;
