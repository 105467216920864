import React from 'react';

const WhatWeDeliver = () => {
  const deliverables = [
    { title: "Sales and Marketing" },
    { title: "Human Resource" },
    { title: "Finance" },
    { title: "Customer Service" },
    { title: "Data and Technology" },
    { title: "Engineering" },
    { title: "Procurement" },
    { title: "Legal and Tax" },
  ];

  return (
    <section className="bg-white py-20 font-poppins">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <div className="container mx-auto px-6 md:px-12">
        <h2 className="font-archivo-black text-2xl md:text-4xl font-extrabold text-center text-customY mb-12 uppercase tracking-wide">
          OUR SERVICES
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {deliverables.map((item, index) => (
            <div
              key={index}
              className="bg-gray-50 p-8 rounded-xl shadow-lg transform hover:-translate-y-2 hover:shadow-2xl transition-all duration-300 flex flex-col justify-center items-center text-center"
            >
              <h3 className="font-archivo-black md:text-lg text-sm text-customBlue mb-4 uppercase tracking-wide">
                {item.title}
              </h3>
              <div className="h-1 w-full bg-customY rounded-full mb-4"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatWeDeliver;
