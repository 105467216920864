import React from 'react';
import backgroundImg from '../assets/airwindow.jpg'; // Ensure this path is correct

const ExperienceSection = () => {
  return (
    <section
      className="relative flex items-center justify-center h-[60vh] md:h-[80vh] text-white"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
        .font-archivo-black {
          font-family: 'Archivo Black', sans-serif;
        }
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }
      `}</style>
      
      {/* Overlay for better text visibility */}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      
      {/* Content */}
      <div className="relative z-10 px-4 md:px-8 lg:px-16 text-left max-w-full md:max-w-[90%] lg:max-w-[80%]">
        <h1 className="font-archivo-black text-lg md:text-2xl lg:text-2xl font-bold leading-tight text-center uppercase">
          A Decade Of Connecting Aviation With The Right Talent
        </h1>
      </div>
    </section>
  );
};

export default ExperienceSection;
