import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kuwaitLogo from '../assets/AirIndia.png'; 
import qatarLogo from '../assets/IndiGo-Logo.png'; 
import salamAirLogo from '../assets/vistara.jpg'; 
import spiceJetLogo from '../assets/akasa.jpg'; 
import wataniyaLogo from '../assets/spicejet.png'; 
import clubLogo from '../assets/club.jpg'; 
import globeLogo from '../assets/globe.jpg'; 
import jetLogo from '../assets/jet.png'; 
import airasiaLogo from '../assets/airasia.png'; 

const partners = [
  { name: "Air India", imgSrc: kuwaitLogo },
  { name: "IndiGo", imgSrc: qatarLogo },
  { name: "Vistara", imgSrc: salamAirLogo },
  { name: "Akasa", imgSrc: spiceJetLogo },
  { name: "SpiceJet", imgSrc: wataniyaLogo },
  { name: "Club Airways", imgSrc: clubLogo },
  { name: "Globe Air", imgSrc: globeLogo },
  { name: "Jet Airways", imgSrc: jetLogo },
  { name: "Air Asia", imgSrc: airasiaLogo }, // Increase size
];

const PartnersSection = () => {
  return (
    <section className="bg-white py-16 font-poppins">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
        .font-archivo-black {
          font-family: 'Archivo Black', sans-serif;
        }
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }

        @keyframes marquee {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(-50%); /* Adjusted for smooth continuous scrolling */
          }
        }

        .animate-marquee {
          display: flex;
          width: 200%; /* Adjusted width to fit all logos twice for seamless scrolling */
          animation: marquee 25s linear infinite; /* Adjusted speed for continuous effect */
        }

        @media (max-width: 768px) {
          .animate-marquee {
            width: 300%; /* Adjust for mobile view */
            animation: marquee 35s linear infinite; /* Adjust timing for mobile view */
          }
        }

        .logo-container {
          width: 150px; /* Increased size for all logos */
          height: 150px;
          padding: 15px; /* Adjust padding as needed */
        }

        .logo-container img {
          max-width: 100%;
          max-height: 100%;
        }
      `}</style>

      <div className="container mx-auto text-center px-6 md:px-20">
        <h2 className="text-center text-customBlue font-archivo-black md:text-3xl text-2xl font-bold  mb-6">
          OUR LEADING PARTNERS
        </h2>
        <p className="font-poppins text-customBlue text-sm md:text-xl font-bold  mb-12">
        We collaborate with leading airlines globally to deliver exceptional talent solutions across multiple sectors and functions.
        </p>
        <div className="overflow-hidden">
          <div className="animate-marquee">
            <div className="flex items-center space-x-12">
              {partners.map((partner, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center logo-container" /* Increased size for all logos */
                >
                  <img
                    src={partner.imgSrc}
                    alt={partner.name}
                    className="object-contain"
                  />
                </div>
              ))}
              {/* Repeat the logos for seamless scrolling */}
              {partners.map((partner, index) => (
                <div
                  key={index + partners.length}
                  className="flex items-center justify-center logo-container" /* Increased size for all logos */
                >
                  <img
                    src={partner.imgSrc}
                    alt={partner.name}
                    className="object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
